import React, { FC, ReactElement, useState, ReactNode, useEffect } from "react";
import {
    createStyles,
    Drawer,
    Theme,
    Tabs,
    Tab,
    IconButton,
    MenuItem,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DocumentTab from "../core/observables/DocumentTab";
import ContextMenu, { Position } from "./ContextMenu";
import { NegroniIcon } from "./icon/NegronIIcon";

const contentStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBar: {
            [theme.breakpoints.up("sm")]: {
                width: `calc(100%)`,
            },
            backgroundColor: "#221E2E",
        },
        toolbar: {
            padding: "0px!important",
            ...theme.mixins.toolbar,
        },
        content: () => ({
            display: "flex",
            flexGrow: 1,
            height: "100%",
            maxHeight: "calc(100vh - 81px)",
            overflowX: "auto",
            overflowY: "auto",
            padding: "0.5rem",
        }),
        propertiesView: (props: { propertiesViewWidth: number }) => ({
            maxWidth: props.propertiesViewWidth,
            width: props.propertiesViewWidth,
            height: "100%",
        }),
        propertiesDrawer: (props: { propertiesViewWidth: number }) => ({
            width: `${props.propertiesViewWidth}px`,
            flexShrink: 0,
        }),
        propertiesDrawerPaper: (props: { propertiesViewWidth: number }) => ({
            width: `${props.propertiesViewWidth}px`,
            minHeight: 32,
            marginTop: "49px",
        }),
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
            margin: 0,
        },
        imageCircle: {
            borderRadius: "50%",
            width: 40,
        },
        tab: {
            maxWidth: "max-content",
            "&:hover": {
                backgroundColor: "var(--hover-color) !important",
            },
            minHeight: 32,
            maxHeight: 32,
        },
        indicator: {
            top: "0px",
        },
    })
);

interface ContentContainerProps {
    propertiesView: ReactElement;
    expandContent: boolean;
    setExpandContent: (expandContent: boolean) => void;
    expandProperties: boolean;
    setExpandProperties: (expandProperties: boolean) => void;
    currentTab: string;
    tabs: DocumentTab[];
    handleTabChange: (document: DocumentTab) => void;
    handleTabClose: (document: DocumentTab) => void;
    handleTablCloseAll: () => void;
    handleTabCloseOthers: (document: DocumentTab) => void;
    handleTabCloseRight: (document: DocumentTab, current: DocumentTab) => void;
    handleTabCloseLeft: (document: DocumentTab, current: DocumentTab) => void;
}

const ContentContainer: FC<ContentContainerProps> = ({
    propertiesView,
    children,
    currentTab,
    tabs,
    handleTabChange,
    handleTabClose,
    handleTablCloseAll,
    handleTabCloseOthers,
    handleTabCloseRight,
    handleTabCloseLeft,
}) => {
    const [propertiesViewWidth, setPropertiesViewWidth] = useState(300); // Initial width
    const classes = contentStyles({ propertiesViewWidth });
    const [currentContent, setCurrentContent] = useState<ReactNode>(children);
    const [selection, setSelection] = useState<DocumentTab | null>(null);
    const [position, setPosition] = useState<Position | null>(null);
    const [isHeld, setIsHeld] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        const document = tabs.find((tab) => tab.name === currentTab);
        if (document) {
            setCurrentContent(document.content);
        } else {
            setCurrentContent(null);
        }
    }, [currentTab, tabs, propertiesView]);

    function handleCloseContextMenu() {
        setPosition(null);
    }

    function handleContextMenu(
        e: React.MouseEvent<HTMLDivElement>,
        document: DocumentTab
    ) {
        setSelection(document);

        e.preventDefault();
        setPosition({
            mouseX: e.clientX,
            mouseY: e.clientY,
        });
    }

    function handleTabCloseContextMenu() {
        if (selection) handleTabClose(selection);
    }

    function handleTabCloseOthersContextMenu() {
        if (selection) handleTabCloseOthers(selection);
    }

    function handleTabCloseRightContextMenu() {
        const currentDocument = tabs.find((tab) => tab.name === currentTab);
        if (selection && currentDocument)
            handleTabCloseRight(selection, currentDocument);
    }

    function handleTabCloseLeftContextMenu() {
        const currentDocument = tabs.find((tab) => tab.name === currentTab);
        if (selection && currentDocument)
            handleTabCloseLeft(selection, currentDocument);
    }

    return (
        <div style={{ display: "flex", width: "100%", position: "relative" }}>
            <div
                style={{
                    flexGrow: 1,
                    display: "flex",
                    flexDirection: "column",
                    overflowX: "auto",
                }}
            >
                <Tabs
                    classes={{
                        indicator: classes.indicator,
                    }}
                    value={currentTab}
                    style={{
                        marginTop: 49,
                        minHeight: 32,
                        backgroundColor: "var(--data-heading-color)",
                    }}
                    variant="scrollable"
                    scrollButtons="off"
                >
                    {tabs.map((document) => (
                        <Tab
                            className={classes.tab}
                            wrapped={true}
                            onClick={() => {
                                handleTabChange(document);
                            }}
                            onContextMenu={(e) => handleContextMenu(e, document)}
                            label={
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                    }}
                                >
                                    <NegroniIcon
                                        iconClass={document.icon}
                                        size="small"
                                        extraClass="negroni-menu-icon"
                                    />
                                    {document.name}
                                    <IconButton
                                        size="small"
                                        color="inherit"
                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            event.stopPropagation();
                                            handleTabClose(document);
                                        }}
                                    >
                                        <NegroniIcon
                                            iconClass="close-icon"
                                            size="small"
                                            extraClass="negroni-menu-icon"
                                        />
                                    </IconButton>
                                </div>
                            }
                            value={document.name}
                        />
                    ))}
                </Tabs>
                <ContextMenu handleClose={handleCloseContextMenu} position={position}>
                    <MenuItem
                        disabled={selection === null}
                        dense
                        onClick={(_) => {
                            handleCloseContextMenu();
                            handleTabCloseContextMenu();
                        }}
                    >
                        Close
                    </MenuItem>
                    {tabs.length > 1 ? (
                        <MenuItem
                            disabled={selection === null}
                            dense
                            onClick={(_) => {
                                handleCloseContextMenu();
                                handleTablCloseAll();
                            }}
                        >
                            Close All
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {tabs.length > 1 ? (
                        <MenuItem
                            disabled={selection === null}
                            dense
                            onClick={(_) => {
                                handleCloseContextMenu();
                                handleTabCloseOthersContextMenu();
                            }}
                        >
                            Close Others
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {selection && !!tabs[tabs.indexOf(selection, 0) + 1] ? (
                        <MenuItem
                            disabled={selection === null}
                            dense
                            onClick={(_) => {
                                handleCloseContextMenu();
                                handleTabCloseRightContextMenu();
                            }}
                        >
                            Close Tabs to the Right
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                    {selection && !!tabs[tabs.indexOf(selection, 0) - 1] ? (
                        <MenuItem
                            disabled={selection === null}
                            dense
                            onClick={(_) => {
                                handleCloseContextMenu();
                                handleTabCloseLeftContextMenu();
                            }}
                        >
                            Close Tabs to the Left
                        </MenuItem>
                    ) : (
                        <></>
                    )}
                </ContextMenu>
                <div className={classes.content}>
                    <div style={{ display: "flex", flexGrow: 1, height: "100%" }}>
                        <div style={{ flexGrow: 1 }}>{currentContent}</div>
                    </div>
                </div>
            </div>
            <div
                className={classes.propertiesView}
                style={{
                    position: "relative",
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        left: -2,
                        top: 0,
                        bottom: 0,
                        width: 3,
                        cursor: "ew-resize",
                        zIndex: 10,
                        backgroundColor: isHeld
                            ? "lightgray"
                            : isHovered
                            ? "lightgray"
                            : "transparent",
                        borderLeft: isHeld ? "3px solid lightgray" : "none",
                        borderRight: isHeld ? "3px solid lightgray" : "none",
                        transition: "background-color 0.2s ease, border 0.2s ease",
                    }}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onMouseDown={() => {
                        setIsHeld(true);
                        const handleMouseMove = (e: MouseEvent) => {
                            const newWidth = window.innerWidth - e.clientX;
                            if (newWidth >= 100 && newWidth <= 800) {
                                setPropertiesViewWidth(newWidth);
                            }
                        };
                        const handleMouseUp = () => {
                            setIsHeld(false);
                            window.removeEventListener("mousemove", handleMouseMove);
                            window.removeEventListener("mouseup", handleMouseUp);
                        };
                        window.addEventListener("mousemove", handleMouseMove);
                        window.addEventListener("mouseup", handleMouseUp);
                    }}
                />
                <Drawer
                    className={classes.propertiesDrawer}
                    variant="permanent"
                    anchor="right"
                    classes={{
                        paper: classes.propertiesDrawerPaper,
                    }}
                >
                    {propertiesView}
                </Drawer>
            </div>
        </div>
    );
};

export default ContentContainer;