import React, {FC, useEffect, useState} from "react";
import {createStyles, CssBaseline, IconButton, Theme, Tooltip, useMediaQuery, useTheme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {Link, useHistory} from "react-router-dom";
import useContent, {LoadContentValue} from "../content/useContent";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Navigator, {PaginatedMockItemTree, PaginatedMockItem, MockDataItemTree} from "../navigator/Navigator";
import OrganizationNavigatorContentProvider, {OrgDocument} from "./OrganizationNavigatorContentProvider";
import SidebarContainer from "../SidebarContainer";
import ContentContainer from "../ContentContainer";
import useService from "../service/useService";
import CDMDocument, {CDMDocumentType} from "../../core/observables/CDMDocument";
import Entity, { mapToAdminCreateEntity, CreateEntityType, mapToCreateEntity, mapToCreateEntityWizard } from "../../core/observables/Entity";
import EntityViewContainer from "../entity/EntityViewContainer";
import Manifest, { makeAdminCreateManifest, CreateManifestWizard } from "../../core/observables/Manifest";
import ManifestViewContainer from "../manifest/ManifestViewContainer";
import {CancelTokenSource, CancelToken} from "axios";
import {EntityViewSkeleton} from "../entity/EntityView";
import {ManifestViewSkeleton} from "../manifest/ManifestView";
import {TypeAttributeFilter} from "../../core/services/EntityService";
import {useSnackbar} from "notistack";
import OrganisationView, {OrganizationViewSkeleton} from "../organisation/OrganisationView";
import Workspace, { CDMContextMenuProvider } from "../../core/observables/Workspace";
import {CDMManager} from "../../core/CDMManager";
import MenuAction from "../navigator/MenuAction";
import CreateEntityByOrgDialog from "../organisation/entity/CreateEntityByOrgDialog";
import { CreateManifestByOrgDialog } from "../organisation/manifest/CreateManifestByOrgDialog";
import useConfirm from "../confirm/useConfirm";
import { PaginatedChildren } from "../navigator/NavigatorContentProvider";
import PropertiesContainer from "../properties/PropertiesContainer";
import useProperties from "../properties/useProperties";
import useBuildManifest from "../manifest/build/useBuildManifest";
import { ManageEntityDialog } from "../entity/ManageEntityDialog";
import DocumentItem from "../../core/observables/DocumentItem";
import TypeAttribute from "../../core/observables/TypeAttribute";
import { DataType } from "../../core/dataType";
import { Purpose } from "../../core/purpose";
import { AddSubManifestDialog } from "../manifest/AddSubManifestDialog";
import { ExportManifestDialog, ExportTypes } from "../manifest/ExportManifestDialog";
import { TriggerType } from "../content/ContentProvider";
import _ from "lodash";
import MainToolbar from "../toolbar/Toolbar";
import ManifestWizard from "../manifest/ManifestWizard";
import EntityWizard from "../entity/EntityWizard";
import ExportDatabaseStructureWizard from "../wizard/ExportDatabaseStructureWizard";
import ExportCdmSchemaWizard from "../wizard/ExportCdmSchemaWizard";
import ExportPackageWizard from "../wizard/ExportPackageWizard";
import { mapToManifest } from "../../core/services/ManifestService";
import ManifestWorkspace from "../../core/observables/ManifestWorkspace";
import useSession from "../session/useSession";
import { NegroniIcon } from "../icon/NegronIIcon";
import { ExportDataModelWizard } from "../wizard/ExportDataModelWizard";

const drawerWidth = 280;
const propertiesViewWidth = 300;

const navigatorStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: 'calc(100% - 104px)',
        },
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('sm')]: {
                width: `calc(100%)`,
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.up('sm')]: {
                display: 'none',
            },
        },
        persistentMenuButton: {
            marginRight: theme.spacing(2),
            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        // necessary for content to be below app bar
        toolbar: {
            padding: '0px!important',
            ...theme.mixins.toolbar
        },
        drawerPaper: {
            width: drawerWidth,
            marginTop: 0,
            borderRight: 0,
            [theme.breakpoints.up('sm')]: {
                marginTop: 70,
                height: 'calc(100% - 70px)',
            },
        },
        persistentDrawerPaper: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            marginTop: 0,
            borderRight: 0,
            [theme.breakpoints.up('sm')]: {
                marginTop: 70,
                height: 'calc(100% - 70px)',
            },
        },
        persistentDrawerOpen: {
            width: drawerWidth,
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        persistentDrawerClose: {
            transition: theme.transitions.create('all', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            overflowX: 'hidden',
            marginLeft: `-${drawerWidth - 70}px`
        },
        content: {
            display: 'flex',
            flexGrow: 1,
            justifyContent: 'flex-end',
            height: '100%',
        },
        propertiesView: {
            maxWidth: propertiesViewWidth,
            width: propertiesViewWidth,
            height: '100%',
        },
        propertiesDrawer: {
            width: `${propertiesViewWidth}px`,
            flexShrink: 0,
        },
        propertiesDrawerPaper: {
            width: `${propertiesViewWidth}px`,
            marginTop: '70px',
        },
        heading: {
            fontSize: theme.typography.pxToRem(15),
            fontWeight: theme.typography.fontWeightRegular,
            margin: 0,
        },
        imageCirle: {
            borderRadius: '50%',
            width: 40,
        },
        navigatorIcon: {
            minWidth: 35,
        },
        fab: {
            marginLeft: '-10px',
            marginTop: '144px',
        },
        splitterOpen: {
            position: 'absolute',
            width: '30px',
            zIndex: 1201,
            height: 'calc(100% - 70px)',
            display: 'block',
            cursor: 'pointer',
            '&:hover': {
                borderLeft: 'solid var(--secondary-color) 2px',
            },
            marginLeft: `${drawerWidth - 2}px`,
        },
        splitterClose: {
            position: 'absolute',
            width: '30px',
            zIndex: 1201,
            height: 'calc(100% - 70px)',
            display: 'block',
            cursor: 'pointer',
            '&:hover': {
                borderLeft: 'solid var(--secondary-color) 2px',
            },
            marginLeft: '62px',
        },
    }),
);

interface AdminProps {}

const Admin: FC<AdminProps> = () => {
    const theme = useTheme();
    const classes = navigatorStyles();
    const {content, setLoadContent, setTriggerType, dirty, busy} = useContent();
    const [selection, setSelection] = useState<any>(null);
    const [updateNavigator, setUpdateNavigator] = useState(0);
    const forceShowNavigator = useMediaQuery(theme.breakpoints.up('sm'));
    const [expanded, setExpanded] = useState<string[]>([]);
    const [navigatorInput, setNavigatorInput] = useState<any>();
    const {manifestService, entityService, adminOrgService, adminEntityService, adminManifestService, navigatorService} = useService();
    const navigatorContentProvider = new OrganizationNavigatorContentProvider(manifestService, entityService, adminOrgService, navigatorService);
    const [openManifestWizard, setOpenManifestWizard] = useState<boolean>(false);
    const [openEntityWizard, setOpenEntityWizard] = useState<boolean>(false);
    const [openExportDatabaseStructureWizard, setOpenExportDatabaseStructureWizard] = useState<boolean>(false);
    const [openExportCdmSchemaWizard, setOpenExportCdmSchemaWizard] = useState<boolean>(false);
    const [openExportDataModelWizard, setOpenExportDataModelWizard] = useState<boolean>(false);
    const [openExportPackageWizard, setOpenExportPackageWizard] = useState<boolean>(false);
    const [expandContent, setExpandContent] = useState(false);
    const [expandProperties, setExpandProperties] = useState(false);
    const {enqueueSnackbar} = useSnackbar();
    const [mobileOpen, setMobileOpen] = useState(false);
    const [selectedNode, setSelectedNode] = useState<any>(null);
    const [selectedOrgCode, setSelectedOrgCode] = useState<string>('');
    const [selectedOrgName, setSelectedOrgName] = useState<string>('');
    const [openCreateEntityOrg, setOpenCreateEntityOrg] = useState(false);
    const [openCreateManifestOrg, setOpenCreateManifestOrg] = useState(false);
    const [openExportManifestDialog, setOpenExportManifestDialog] = useState<boolean>(false);
    const [openAddEntityManifestDialog, setOpenAddEntityManifestDialog] = useState(false);
    const [openAddSubManifestDialog, setOpenAddSubManifestDialog] = useState(false);
    const [addEntityManifest, setAddEntityManifest] = useState<Manifest>();
    const [addSubManifestManifest, setAddSubManifestManifest] = useState<Manifest>();
    const [exportManifest, setExportManifest] = useState<Manifest>();
    const [openImportWizard, setOpenImportWizard] = useState<boolean>(false);
    const confirm = useConfirm();
    const {setProperty} = useProperties();
    const openBuildManifest = useBuildManifest();
    const [exportMode, setExportMode] = useState<ExportTypes>(ExportTypes.CDM_SCHEMA);
    const history = useHistory();
    const [coreFolderRefresh, setCoreFolderRefresh] = useState(false);
    const menuActions: MenuAction[] = [
        {
            supported: (node: any) => {
                return (node?.label === 'Entities' && node?.type === 'paginatedMockItem' &&
                    node?.parent?.documentType === CDMDocumentType.ORG) ||
                    node?.documentType === CDMDocumentType.ORG
            },
            action: (node: any) => {
                setSelectedNode(node);
                let theNode = node?.documentType === CDMDocumentType.ORG ? node : node?.parent;
                setSelectedOrgCode(theNode?.path);
                setSelectedOrgName(theNode?.name);
                setOpenCreateEntityOrg(true);
            },
            title: 'Create Global Entity',
            icon: 'description-icon',
            children: [],
        },
        {
            supported: (node: any) => {
                return (node?.label === 'Manifests' && node?.type === 'paginatedMockItem' &&
                    node?.parent?.documentType === CDMDocumentType.ORG) ||
                    node?.documentType === CDMDocumentType.ORG
            },
            action: (node: any) => {
                setSelectedNode(node);
                let theNode = node?.documentType === CDMDocumentType.ORG ? node : node?.parent;
                setSelectedOrgCode(theNode?.path);
                setSelectedOrgName(theNode?.name);
                setOpenCreateManifestOrg(true);
            },
            title: 'Create Manifest',
            icon: 'assignment-icon',
            children: [],
        },
        {
            supported: (node: any) => {
                return (node?.documentType === CDMDocumentType.MANIFEST && !node?.isDefault) ||
                    (node?.label === 'Entities' && node?.type === 'mockDataItem' &&
                        (node?.parent?.documentType === CDMDocumentType.MANIFEST || node?.parent?.documentType === CDMDocumentType.ENTITY));
            },
            action: (node: any) => {
                let manifestId: number | undefined;
                let parent: any;
                let theNode: any;
                if (node?.type === 'mockDataItem') {
                    const _parent = node?.parent;
                    parent = node;
                    theNode = _parent?.parent?.parent;
                    setSelectedOrgCode(theNode?.path);
                    setSelectedOrgName(theNode?.name);
                    if (_parent.documentType === CDMDocumentType.MANIFEST || _parent.documentType === CDMDocumentType.ENTITY)
                        manifestId = _parent.id;
                } else {
                    theNode = node?.parent?.parent;
                    setSelectedOrgCode(theNode?.path);
                    setSelectedOrgName(theNode?.name);
                    manifestId = node?.id;
                    parent = new MockDataItemTree('Entities', node);
                }

                if (manifestId) {
                    manifestService.getManifestById(manifestId)
                        .then(manifest => {
                            manifest.parent = parent;
                            handleAddEntityManifestDialog(manifest);
                        }).catch(e => enqueueSnackbar(`Unable to open add entity dialog - ${e?.message}`, {variant: 'error'}));
                } else {
                    enqueueSnackbar('Unable to open add entity dialog - no manifest parent.');
                }
            },
            title: 'Add Entity',
            icon: 'description-icon',
            children: [],
        },
        {
            supported: (node: any) => {
                return (node?.documentType === CDMDocumentType.MANIFEST && !node?.isDefault) ||
                    (node?.label === 'Sub-Manifests' && node?.type === 'mockDataItem' &&
                        (node?.parent?.documentType === CDMDocumentType.MANIFEST || node?.parent?.documentType === CDMDocumentType.SUB_MANIFEST));
            },
            action: (node: any) => {
                let manifestId: number | undefined;
                let parent: any;
                let theNode: any;
                if (node?.type === 'mockDataItem') {
                    const _parent = node?.parent;
                    parent = node;
                    theNode = _parent?.parent?.parent;
                    setSelectedOrgCode(theNode?.path);
                    setSelectedOrgName(theNode?.name);
                    if (_parent.documentType === CDMDocumentType.MANIFEST || _parent.documentType === CDMDocumentType.SUB_MANIFEST)
                        manifestId = _parent.id;
                } else {
                    theNode = node?.parent?.parent;
                    setSelectedOrgCode(theNode?.path);
                    setSelectedOrgName(theNode?.name);
                    parent = new MockDataItemTree('Sub-Manifests', node);
                    manifestId = node?.id;
                }

                if (manifestId) {
                    manifestService.getManifestById(manifestId)
                        .then(manifest => {
                            manifest.parent = parent;
                            handleAddSubManifestDialog(manifest);
                        })
                        .catch(e => {
                            enqueueSnackbar(`Unable to open add sub-manifest dialog - ${e?.message}`, {variant: 'error'});
                        });
                } else {
                    enqueueSnackbar('Unable to open add sub-manifest dialog - no manifest parent.');
                }
            },
            title: 'Add Sub-Manifest',
            icon: 'assignment-icon',
            children: [],
        },
        {
            supported: (node: any) => {
                const parent = node?.parent;
                if (parent?.type !== 'paginatedMockItem' || parent?.parent?.documentType !== CDMDocumentType.ORG)
                    return false;
                return node?.documentType === CDMDocumentType.MANIFEST && !node?.isDefault;
            },
            action: () => {},
            title: 'Export',
            icon: 'import-export-icon',
            children: [
                {
                    supported: (node: any) => {
                        const parent = node?.parent;
                        if (parent?.type !== 'paginatedMockItem' || parent?.parent?.documentType !== CDMDocumentType.ORG)
                            return false;
                        return node?.documentType === CDMDocumentType.MANIFEST && !node?.isDefault;
                    },
                    action: async (node: any) => {
                        if (!busy.current) {
                            manifestService.getManifestById(node.id)
                                .then(manifest => {
                                    openBuildManifest(manifest, true, false)
                                })
                                .catch(e => enqueueSnackbar(`Unable to open build manifest dialog - ${e?.message}`, {variant: 'error'}));
                        }
                    },
                    title: 'Martini Package',
                    icon: 'wrench-icon',
                    children: [],
                },
                {
                    supported: (node: any) => {
                        const parent = node?.parent;
                        if (parent?.type !== 'paginatedMockItem' || parent?.parent?.documentType !== CDMDocumentType.ORG)
                            return false;
                        return node?.documentType === CDMDocumentType.MANIFEST && !node?.isDefault;
                    },
                    action: async (node: any) => {
                        setExportMode(ExportTypes.DATABASE_SCHEMA)
                        if (!busy.current) {
                            manifestService.getManifestById(node.id)
                                .then(manifest => {
                                    handleExportManifestDialog(manifest);
                                })
                                .catch(e => enqueueSnackbar(`Unable to open export manifest dialog - ${e?.message}`, {variant: 'error'}));
                        }
                    },
                    title: 'Database Structure',
                    icon: 'storage-icon',
                    children: [],
                },
                {
                    supported: (node: any) => {
                        const parent = node?.parent;
                        if (parent?.type !== 'paginatedMockItem' || parent?.parent?.documentType !== CDMDocumentType.ORG)
                            return false;
                        return node?.documentType === CDMDocumentType.MANIFEST && !node?.isDefault;
                    },
                    action: async (node: any) => {
                        setExportMode(ExportTypes.CDM_SCHEMA);
                        if (!busy.current) {
                            manifestService.getManifestById(node.id)
                                .then(manifest => {
                                    handleExportManifestDialog(manifest);
                                })
                                .catch(e => enqueueSnackbar(`Unable to open export manifest dialog - ${e?.message}`, {variant: 'error'}));
                        }
                    },
                    title: 'CDM Schema',
                    icon: 'description-icon',
                    children: [],
                },
            ],
        },
        {
            supported: (node: any) => {
                const parent = node?.parent;
                if (parent?.type !== 'mockDataItem' || parent?.parent?.documentType !== CDMDocumentType.MANIFEST)
                    return false;
                return node?.documentType === CDMDocumentType.SUB_MANIFEST && !node?.isDefault;
            },
            action: (node: any) => {
                if (!busy.current) {
                    const manifestId = node.parent.parent.id;
                    confirm({message: 'Do you really want to delete the sub-manifest?', title: 'Delete Sub-Manifest'})
                        .then(() => {
                            manifestService.deleteSubManifest(manifestId, node.id)
                                .then(_ => {
                                    localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
                                    enqueueSnackbar('Successfully deleted a sub-manifest', {variant: 'success'});
                                    setSelection(null);
                                    setLoadContent(getEmptyView());
                                    refresh();
                                })
                                .catch(e => {
                                    enqueueSnackbar(`Failed to delete sub-manifest: ${e.message}`, {variant: 'error'});
                                });
                        });
                }
            },
            title: 'Delete',
            icon: 'delete-icon',
            className: 'delete',
            children: [],
        },
        {
            supported: (node: any) => {
                return (node?.documentType === CDMDocumentType.ENTITY)
            },
            action: (node: any) => {
                if(!busy.current) {
                    confirm({message: 'Do you really want to delete the entity?', title: 'Delete Entity'})
                        .then(() => {
                            if(node.parentId) {
                                manifestService.deleteEntity(node.parentId, node.id)
                                    .then(_ => {
                                        localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
                                        enqueueSnackbar('Successfully deleted a entity', {variant: 'success'});
                                        setSelection(null);
                                        setLoadContent(getEmptyView());
                                        refresh();
                                    })
                                    .catch(e => enqueueSnackbar(`Failed to delete entity: ${e.message}`, {variant: 'error'}));
                            } else {
                                entityService.deleteEntity(node.id)
                                    .then(_ => {
                                        localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
                                        enqueueSnackbar('Successfully deleted a entity', {variant: 'success'});
                                        setSelection(null);
                                        setLoadContent(getEmptyView());
                                        setProperty(null);
                                        refresh();
                                        if(node.isDefault) {
                                            refreshStructure();
                                        }
                                    })
                                    .catch(e => enqueueSnackbar(`Failed to delete entity: ${e.message}`, {variant: 'error'}));
                            }
                        });
                }
            },
            title: 'Delete',
            icon: 'delete-icon',
            className: 'delete',
            children: [],
        },
        {
            supported: (node: any) => {
                return (node?.parent?.label === 'Manifests' && node?.parent?.type === 'paginatedMockItem' &&
                    node?.documentType === CDMDocumentType.MANIFEST && !node?.isDefault)
            },
            action: async (node: any) => {
                if(!busy.current) {
                    try {
                        const parentManifests = await manifestService.getParentManifests(node.id);
                            let warningMessage = 'Do you really want to delete the manifest?';

                            if (parentManifests.length > 0) {
                                let names = parentManifests.slice(0, 5).map(p => `<li><strong>${p.name}</strong></li>`).join('');
                                if (parentManifests.length > 5)
                                    names = names + '<li><i>and more...</i></li>';
                                warningMessage = `Manifest <strong>${node.name}</strong> is being referenced as sub-manifest in:
                                                    <ul>${names}</ul>
                                                    Do you want to proceed deleting the manifest?`;
                            }

                            confirm({message: warningMessage, title: 'Delete Manifest'})
                                .then(_ => {
                                    manifestService.deleteManifest(node.id)
                                        .then(_ => {
                                            localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
                                            enqueueSnackbar('Successfully deleted a manifest', {variant: 'success'});
                                            setSelection(null);
                                            setLoadContent(getEmptyView());
                                            setProperty(null);
                                            refresh();
                                        })
                                        .catch(e => enqueueSnackbar(`Failed to delete manifest: ${e.message}`, {variant: 'error'}));
                                });
                    } catch (e) {
                        enqueueSnackbar('Failed to delete manifest for unknown reason', {variant: 'error'});
                    }
                }
            },
            title: 'Delete',
            icon: 'delete-icon',
            className: 'delete',
            children: [],
        },
        {
            supported: (node: any) => {
                return (node?.documentType === CDMDocumentType.WORKSPACE && node?.isDefault)
            },
            action: _ => {
                if(!busy.current) {
                    confirm({message: 'Do you really want to refresh core structure?', title: 'Refresh Structure'})
                        .then(() => {
                            refreshStructure();
                        });
                }
            },
            title: 'Refresh Structure',
            icon: 'refresh-icon',
            children: [],
        },
        {
            supported: (node: any) => {
                return node?.documentType === CDMDocumentType.MANIFEST && node?.isDefault;
            },
            action: async (node: any) => {
                if (!busy.current) {
                    manifestService.getManifestById(node.id)
                        .then(manifest => {
                            openBuildManifest(manifest, true, false)
                        })
                        .catch(e => enqueueSnackbar(`Unable to open build manifest dialog - ${e?.message}`, {variant: 'error'}));
                }
            },
            title: 'Build Martini Package',
            icon: 'wrench-icon',
            children: [],
        }
    ];

    useEffect(() => {
        if(!selection) {
            setLoadContent(getEmptyView());
        }
        if (selection && !busy.current) {
            if (selection.documentType === CDMDocumentType.ENTITY) {
                const entityOrg = getSelectionOrg(selection);
                const orgLabel = getSelectionOrgLabel(selection);
                const entityParent = selection?.parent;
                const typeAttributeFilter = selection.isDefault ? TypeAttributeFilter.ALL : TypeAttributeFilter.UNRESOLVED;
                const entity = (cancelToken: CancelTokenSource) => entityService.getEntityById(selection.id, typeAttributeFilter, undefined, cancelToken.token)
                    .then(entity => {
                        entity.targetManifest = selection.parentId;
                        return entity;
                    });
                setExpandContent(true);
                setLoadContent(getViewByDocument(CDMDocumentType.ENTITY, entity, entityParent, entityOrg, orgLabel));
                selection.typeAttributes = [];
                selection.entityAttributes = [];
            } else if (selection.documentType === CDMDocumentType.MANIFEST) {
                const manifestOrg = selection?.parent?.parent?.path;
                const orgLabel = selection?.parent?.parent?.name;
                const manifestParent = selection?.parent;
                const manifest = (cancelToken: CancelTokenSource) => manifestService.getManifestById(selection.id, cancelToken.token);
                setExpandContent(true);
                setLoadContent(getViewByDocument(CDMDocumentType.MANIFEST, manifest, manifestParent, manifestOrg, orgLabel));
            } else if (selection.documentType === CDMDocumentType.SUB_MANIFEST) {
                const subManifestParent = selection?.parent;
                const subManifest = (cancelToken: CancelTokenSource) => manifestService.getManifestById(selection.id, cancelToken.token)
                    .then(manifest => {
                        manifest.parentManifestId = selection.parentId;
                        return manifest;
                    });
                setExpandContent(true);
                setLoadContent(getViewByDocument(CDMDocumentType.MANIFEST, subManifest, subManifestParent));
            } else if (selection.documentType === CDMDocumentType.ORG) {
                const orgSummary = (cancelToken: CancelTokenSource) => adminOrgService.getOrgSummary(selection.path, cancelToken.token)
                    .then(org => {
                        return org.organisation
                    });
                setExpandContent(true);
                setLoadContent(getViewByDocument(CDMDocumentType.ORG, orgSummary, undefined));
            }
            if(localStorage.getItem(CDMManager.NEGRONI_RESET_SELECTED_NODE)) {
                localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
                localStorage.removeItem(CDMManager.NEGRONI_RESET_SELECTED_NODE);
            } else {
                localStorage.setItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE, JSON.stringify(handleForceSelection(selection)));
            }
        }
    }, [selection]);

    useEffect(() => {
        setLoadContent(undefined);
        setNavigatorInput([
            new Workspace(CDMManager.PRE_DEFINED, true),
        ]);
        localStorage.setItem(CDMManager.NEGRONI_LAST_VISIT, 'Admin');
        if(localStorage.getItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE) !== null) {
            const currentSelected = JSON.parse(localStorage.getItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE) || '{}');
            setSelection(currentSelected);
        }
    }, []);

    function refreshStructure() {
        setNavigatorInput([
            new PaginatedMockItem('org', 'Organisations', 'business', getOrgs, undefined)
        ]);
        setCoreFolderRefresh(true);
        navigatorService.regenerateNavigator()
            .then(response => {
                localStorage.setItem(CDMManager.NEGRONI_CORE_SCHEMA_KEY, JSON.stringify(response?.coreSchema?.nodes));
                localStorage.setItem(CDMManager.NEGRONI_CORE_SCHEMA_VERSION_KEY, response?.coreSchema?.buildVersion);
                enqueueSnackbar('Successfully refreshed core structure', {variant: 'success'});
                setSelection(null);
                setLoadContent(getEmptyView());
                refresh();
                setCoreFolderRefresh(false);
                setNavigatorInput([
                    new Workspace(CDMManager.PRE_DEFINED, true)
                ]);
            })
            .catch(e => enqueueSnackbar(`Failed to refresh core structure: ${e.message}`, {variant: 'error'}));
    }

    function refresh() {
        setUpdateNavigator(updateNavigator + 1);
    }

    function handleForceSelection(node: any): any {
        const nodeClone = _.cloneDeep(node);
        nodeClone.parent = makeLastParent(nodeClone.parent);
        nodeClone.children = [];
        return nodeClone;
    }

    function makeLastParent(parent: any): any {
        if(Array.isArray(parent)) {
            return undefined;
        } else if(parent === undefined) {
            return undefined;
        } else {
            parent.children = [];
            parent.parent = makeLastParent(parent.parent);
            return parent;
        }
    }

    function handleCreateEntityByOrg(org: string, entity: Entity) {
        return adminEntityService.createEntity(mapToAdminCreateEntity(org, entity, CreateEntityType.TEMPLATE))
            .then(response => {
                const typeAttributeFilter = response.isDefault ? TypeAttributeFilter.ALL : TypeAttributeFilter.UNRESOLVED;
                const entity = (cancelToken: CancelTokenSource) => entityService.getEntityById(response.id, typeAttributeFilter, undefined, cancelToken.token)
                    .then(entity => {
                        entity.targetManifest = response.targetManifest;
                        return entity;
                    });
                setExpandContent(true);
                setLoadContent(getViewByDocument(CDMDocumentType.ENTITY, entity, undefined));
                enqueueSnackbar('Successfully created entity.', {variant: 'success'});
                let parent = selectedNode?.documentType !== CDMDocumentType.ORG ? selectedNode?.parent : selectedNode;
                response.parent = new PaginatedMockItemTree('Entities', parent);
                localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                refresh();
                setSelection(response);
            })
            .catch(error => error || 'Failed to create entity.');
    }

    function handleCreateManifestByOrg(org: string, manifest: Manifest) {
        return adminManifestService.createManifest(makeAdminCreateManifest(org, manifest))
            .then(response => {
                const manifest = (cancelToken: CancelTokenSource) => manifestService.getManifestById(response.id, cancelToken.token);
                setExpandContent(true);
                setLoadContent(getViewByDocument(CDMDocumentType.MANIFEST, manifest, undefined));
                enqueueSnackbar('Successfully created manifest.', {variant: 'success'});
                let parent = selectedNode?.documentType !== CDMDocumentType.ORG ? selectedNode?.parent : selectedNode;
                response.parent = new PaginatedMockItemTree('Manifests', parent);
                localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                refresh();
                setSelection(response);
            })
            .catch(error => error || 'Failed to create manifest');
    }

    const handleCreateManifestViaWizard = (manifest: CreateManifestWizard): Promise<string | undefined> => {
        return manifestService.createManifestViaWizard(manifest)
            .then(data => {
                if(data.generateType === 'Manifest') {
                    localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                    const manifest = mapToManifest(data.manifest.id, data.manifest);
                    enqueueSnackbar(`Manifest '${manifest.name}' successfully built`, {variant: 'success'});
                    //refreshManifestNavigator();
                } else {
                    localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                    const manifest = mapToManifest(data.manifest.id, data.manifest);
                    enqueueSnackbar(`Manifest '${manifest.name}' successfully built`, {variant: 'success'});
                    //refreshManifestNavigator();
                    history.push({pathname: '/packages', search: `?new=${data.manifest.name}`});
                }
         
            }).catch(error => {
                return error || 'Failed to create manifest';
            });
    }

    const handleCreateEntityViaWizard = (entity: Entity, manifest?: Manifest, duplicateEntityId?: number): Promise<string | undefined> => {
        const primaryKey = new TypeAttribute('id', DataType.INTEGER);
        primaryKey.purpose = Purpose.IDENTIFIED_BY;
        entity.typeAttributes = [...entity.typeAttributes, primaryKey];
        entity.type = manifest ? CreateEntityType.EXCLUSIVE : CreateEntityType.TEMPLATE;
        return entityService.createEntityWizard(mapToCreateEntityWizard(entity, entity?.type || CreateEntityType.EXCLUSIVE, manifest?.id || -1, duplicateEntityId || -1))
            .then(entity => {
                localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                if(manifest) {
                    const manifestWorkspace = new ManifestWorkspace(manifest.id, manifest.name)
                    entity.targetManifest = manifest.id;
                    entity.parent = new PaginatedMockItemTree('Entities', manifestWorkspace);;
                }
                refresh();
                setSelection(entity);
                enqueueSnackbar('Successfully created entity.', {variant: 'success'});
            })
            .catch(error => error || 'Failed to create entity');
    }

    const getOrgs = (limit: number = 20, page: number = 0, search: string = '', cancelToken?: CancelToken) => {
        return adminOrgService.getOrgs(page, limit, search, cancelToken)
            .then(result => ({
                children: result.result,
                totalElements: result.totalElements,
            } as PaginatedChildren))
    }

    const handleExportManifestDialog = (manifest: Manifest) => {
        setExportManifest(manifest);
        setOpenExportManifestDialog(true);
    }

    const handleCloseAddSubManifestDialog = () => {
        setOpenAddSubManifestDialog(false);
    }

    const handleOpenManageEntityDialog = () => {
        setOpenAddEntityManifestDialog(true);
    };

    const handleAddSubManifestDialog = (manifest: Manifest) => {
        setAddSubManifestManifest(manifest);
        setOpenAddSubManifestDialog(true);
    }

    const handleAddEntityManifestDialog = (manifest: Manifest) => {
        setAddEntityManifest(manifest);
        handleOpenManageEntityDialog();
    }

    const handleCloseManageEntityDialog = () => {
        setOpenAddEntityManifestDialog(false);
    };

    const handleCloseManifestWizard = () => {
        setOpenManifestWizard(false);
    };

    const handleOpenManifestWizard = () => {
        setOpenManifestWizard(true);
    };

    const handleCloseEntityWizard = () => {
        setOpenEntityWizard(false);
    };

    const handleOpenEntityWizard = () => {
        setOpenEntityWizard(true)
    };

    const handleCloseExportDatabaseStructureWizard = () => {
        setOpenExportDatabaseStructureWizard(false);
    };

    const handleOpenExportDatabaseStructureWizard = () => {
        setOpenExportDatabaseStructureWizard(true)
    };

    const handleCloseExportCdmSchemaWizard = () => {
        setOpenExportCdmSchemaWizard(false);
    };

    const handleOpenExportCdmSchemaWizard = () => {
        setOpenExportCdmSchemaWizard(true)
    };

    const handleCloseExportPackageWizard = () => {
        setOpenExportPackageWizard(false);
    };

    const handleOpenExportPackageWizard = () => {
        setOpenExportPackageWizard(true)
    };

    const handleOpenImportWizard = () => {
        setOpenImportWizard(true)
    };

    const onAddSubManifest = (subManifest: DocumentItem, manifest: Manifest): Promise<string | undefined> => {
        return manifestService.addSubManifest(manifest.id, [subManifest.id])
            .then(_ => {
                localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                refresh();
                subManifest.parent = manifest.parent;
                setSelection(subManifest);
                enqueueSnackbar('Successfully added sub-manifest to manifest.', {variant: 'success'});
            }).catch(error => error || 'Failed to add sub-manifest to manifest');
    }

    const handleAddEntity = (entity: DocumentItem, manifest: Manifest): Promise<string | undefined> => {
        const entityIds = manifest.entityPaths.map(item => item.id);
        return manifestService.addEntity(manifest.id, [entity.id], CreateEntityType.EXCLUSIVE)
            .then(_manifest => {
                localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                refresh();
                const newEntity = _manifest.entityPaths.find(item => !entityIds.includes(item.id));
                if (newEntity) {
                    newEntity.parent = manifest.parent;
                    setSelection(newEntity);
                    enqueueSnackbar('Successfully added entity to manifest.', {variant: 'success'});
                }
            })
            .catch(error => error || 'Failed to add entity to manifest');
    };

    const handleCreateExclusiveEntity = (entity: Entity, manifest: Manifest): Promise<string | undefined> => {
        const primaryKey = new TypeAttribute('id', DataType.INTEGER);

        primaryKey.purpose = Purpose.IDENTIFIED_BY;
        entity.typeAttributes = [primaryKey];
        return entityService.createEntity(mapToCreateEntity(entity, entity?.type || CreateEntityType.EXCLUSIVE, manifest.id))
            .then(entity => {
                localStorage.setItem(CDMManager.NEGRONI_RESET_SELECTED_NODE, 'true');
                entity.targetManifest = manifest.id;
                entity.parent = manifest.parent;
                refresh();
                setSelection(entity);
                enqueueSnackbar('Successfully created local entity to manifest.', {variant: 'success'});
            })
            .catch(error => error || 'Failed to create local entity to manifest');
    };

    const handleExportManifest = (manifest: Manifest, exportType: ExportTypes, databaseType: string = 'postgresql', applyAutoIncrement: boolean): Promise<string | undefined> => {
        return manifestService.exportManifestDatabaseStructure(manifest.id, exportType, databaseType, applyAutoIncrement)
            .then(data => {
                const tempLink = document.createElement('a');
                tempLink.href = window.URL.createObjectURL(new Blob([data], {type: 'application/zip;charset=UTF-8'}));
                tempLink.download = `${manifest.name}-${exportType === ExportTypes.DATABASE_SCHEMA ? databaseType : 'cdm'}.zip`
                tempLink.click();
            }).catch(error => {
                return error || 'Failed to export manifest';
            });
    };

    const getEmptyView = (): LoadContentValue => ({document: undefined, view: _ => <div/>});

    const getEntityView = (entity: Entity, entityOrg?: string, orgLabel?: string): React.ReactElement => {
        return <EntityViewContainer
            entity={entity}
            onChange={(document, message) => {
                refresh();
                setSelection(document);
                if (message) enqueueSnackbar(message, {variant: 'success'});
            }}
            refreshStructure={refreshStructure}
            viewMode={'Admin'}
            entityOrg={entityOrg}
            orgLabel={orgLabel}
        />;
    };

    const getManifestView = (manifest: Manifest, manifestOrg?: string, orgLabel?: string) => {
        return <ManifestViewContainer
            manifest={manifest}
            onChange={(document, message) => {
                refresh();
                setSelection(document);
                if (message) enqueueSnackbar(message, {variant: 'success'});
            }}
            viewMode={'Admin'}
            manifestOrg={manifestOrg}
            orgLabel={orgLabel}
        />;
    };

    const getOrganisationView = (org: OrgDocument) => {
        return <OrganisationView 
            org={org}
            onCreateEntity={handleCreateEntityByOrg}
            onCreateManifest={handleCreateManifestByOrg}
        />
    };

    const getViewByDocument = (
        documentType: CDMDocumentType,
        document: (cancelToken: CancelTokenSource) => Promise<CDMDocument>,
        parent: any,
        documentOrg?: string,
        orgLabel?: string
    ): LoadContentValue | undefined => {
        if (documentType === CDMDocumentType.ENTITY) {
            return {
                document,
                view: document => {
                    document.parent = parent;
                    return getEntityView(document as Entity, documentOrg, orgLabel)
                },
                options: {loader: <EntityViewSkeleton/>},
            };
        } else if (documentType === CDMDocumentType.MANIFEST) {
            return {
                document,
                view: document => {
                    document.parent = parent;
                    return getManifestView(document as Manifest, documentOrg, orgLabel)
                },
                options: {loader: <ManifestViewSkeleton/>},
            };
        } else if (documentType === CDMDocumentType.ORG) {
            return {
                document,
                view: document => {
                    document.documentType = documentType;
                    return getOrganisationView(document as OrgDocument)
                },
                options: {loader: <OrganizationViewSkeleton/>},
            }
        }
    }

    const getSelectionOrg = (selection: any): any => {
        if(!selection?.isDefault) {
            if(selection?.documentType === CDMDocumentType.ORG) {
                return selection?.path;
            } else {
                return getSelectionOrg(selection?.parent)
            }
        }
        return undefined;
    }

    const getSelectionOrgLabel = (selection: any): any => {
        if(!selection?.isDefault) {
            if(selection?.documentType === CDMDocumentType.ORG) {
                return selection?.name;
            } else {
                return getSelectionOrgLabel(selection?.parent)
            }
        }
        return undefined;
    }

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    return <>
        <div className={classes.root}>
            <CssBaseline/>
            <Header
                onStartOnBoarding={() => {}}
                onGuidedTours={() => {}}
                onDrawerToggle={handleDrawerToggle}
            >
                <Tooltip title='Packages'>
                    <Link
                        onClick={() => {
                            if(dirty) {
                                confirm({message: 'There are unsaved changes, are you sure you want to proceed to package view?', title: 'Package View'})
                                    .then(() => {
                                        localStorage.setItem(CDMManager.NEGRONI_LAST_VISIT, 'Default');
                                        setTriggerType(TriggerType.LOGOUT);
                                        history.push('/packages');
                                    });
                            }
                            else {
                                localStorage.setItem(CDMManager.NEGRONI_LAST_VISIT, 'Default');
                                history.push('/packages');
                            }
                        }}
                        to='#'
                        style={{textDecoration: 'none'}}
                    >
                        <IconButton color='primary'>
                            <NegroniIcon iconClass="card-travel-icon" color="#ddd1f0"/>
                        </IconButton>
                    </Link>
                </Tooltip>
                <Tooltip title='Home'>
                    <Link
                        onClick={() => { 
                            localStorage.removeItem(CDMManager.NEGRONI_CURRENT_SELECTED_NODE);
                        }}
                        to='/'
                        style={{textDecoration: 'none'}}
                    >
                        <IconButton color='primary'>
                            <NegroniIcon iconClass="home-icon" color="#ddd1f0"/>
                        </IconButton>
                    </Link>
                </Tooltip>
            </Header>
            <MainToolbar
                openManifestWizard={handleOpenManifestWizard}
                openEntityWizard={handleOpenEntityWizard}
                openExportDatabaseStructure={handleOpenExportDatabaseStructureWizard}
                openExportCdmSchema={handleOpenExportCdmSchemaWizard}
                openExportDataModel={() => setOpenExportDataModelWizard(true)}
                openExportPackage={handleOpenExportPackageWizard}
                openImportWizard={handleOpenImportWizard}
                manifestSize={0}
                onStartOnBoarding={() => {}}
                onGuidedTours={() => {}}
                onDrawerToggle={handleDrawerToggle}
            />
            <SidebarContainer
                mobile={mobileOpen}
                onMobileClose={() => setMobileOpen(false)}
                openPersistentDrawer = {true}
            >
                {openPersistentDrawer =>
                    <Sidebar
                        showSearch={true}
                    >
                        {search => <div style={{overflow: 'auto', display: openPersistentDrawer || !forceShowNavigator ? 'flex' : 'none', height: '100%'}}>
                            <Navigator
                                contextMenuProvider={new CDMContextMenuProvider(menuActions)}
                                defaultSelected={selection}
                                update={updateNavigator}
                                search={search}
                                input={navigatorInput}
                                contentProvider={navigatorContentProvider}
                                onSelect={(node: any) => {
                                    if (node.documentType === CDMDocumentType.ENTITY ||
                                        node.documentType === CDMDocumentType.MANIFEST ||
                                        node.documentType === CDMDocumentType.SUB_MANIFEST ||
                                        node.documentType === CDMDocumentType.ORG) {
                                        setSelection(node || null);
                                        if(node.documentType === CDMDocumentType.ORG) {
                                            setSelectedNode(node || null);
                                        }
                                    }
                                }}
                                expanded={expanded}
                                setExpanded={setExpanded}
                                isLoading={coreFolderRefresh}
                                isLoadingText="Refreshing Core Folder"
                            />
                        </div>}
                    </Sidebar>}
            </SidebarContainer>
            <ContentContainer
                propertiesView={<PropertiesContainer/>}
                expandContent={expandContent}
                expandProperties={expandProperties}
                setExpandContent={setExpandContent}
                setExpandProperties={setExpandProperties}
                currentTab={''}
                tabs={[]}
                handleTabChange={() => {}}
                handleTabClose={() => {}}
                handleTablCloseAll={() => {}}
                handleTabCloseOthers={() => {}}
                handleTabCloseRight={() => {}}
                handleTabCloseLeft={() => {}}
            >
                {content}
            </ContentContainer>
            <CreateEntityByOrgDialog
                orgCode={selectedOrgCode}
                orgName={selectedOrgName}
                defaultEntityName='new_entity'
                open={openCreateEntityOrg}
                onClose={() => {
                    setOpenCreateEntityOrg(false);
                }}
                onCancel={() => {
                    setOpenCreateEntityOrg(false);
                }}
                onFinish={handleCreateEntityByOrg}
            />
            <CreateManifestByOrgDialog
                orgCode={selectedOrgCode}
                orgName={selectedOrgName}
                open={openCreateManifestOrg}
                onClose={() => {
                    setOpenCreateManifestOrg(false);
                }}
                onCancel={() => {
                    setOpenCreateManifestOrg(false);
                }}
                onFinish={handleCreateManifestByOrg}
            />
            <ManageEntityDialog
                open={openAddEntityManifestDialog}
                onClose={handleCloseManageEntityDialog}
                manifest={addEntityManifest}
                entityService={entityService}
                adminOrgService={adminOrgService}
                onFinishAddEntity={handleAddEntity}
                onFinishCreateExclusiveEntity={handleCreateExclusiveEntity}
                viewMode={'Admin'}
                manifestOrg={selectedOrgCode}
                orgLabel={selectedOrgName}
            />
            <AddSubManifestDialog
                open={openAddSubManifestDialog && !!addSubManifestManifest}
                onClose={handleCloseAddSubManifestDialog}
                manifest={addSubManifestManifest}
                manifestService={manifestService}
                adminOrgService={adminOrgService}
                onFinish={onAddSubManifest}
                viewMode={'Admin'}
                manifestOrg={selectedOrgCode}
                orgLabel={selectedOrgName}
            />
            <ExportManifestDialog
                open={openExportManifestDialog}
                onClose={() => {
                    setOpenExportManifestDialog(false);
                }}
                exportMode={exportMode}
                manifest={exportManifest || new Manifest(-1, '1.0.0', '')}
                onExportManifest={handleExportManifest}
            />
            <ManifestWizard
                open={openManifestWizard}
                onClose={handleCloseManifestWizard}
                initialManifestName='new_manifest'
                onCreateManifestViaWizard={handleCreateManifestViaWizard}
                manifestSize={0}
            />
            <EntityWizard
                open={openEntityWizard}
                onClose={handleCloseEntityWizard}
                onCreateEntityViaWizard={handleCreateEntityViaWizard}
                viewMode={'Admin'}
            />
            <ExportDatabaseStructureWizard
                open={openExportDatabaseStructureWizard}
                onClose={handleCloseExportDatabaseStructureWizard}
                onExportManifest={handleExportManifest}
            />
            <ExportCdmSchemaWizard
                open={openExportCdmSchemaWizard}
                onClose={handleCloseExportCdmSchemaWizard}
                onExportManifest={handleExportManifest}
            />
            <ExportDataModelWizard
                open={openExportDataModelWizard}
                onClose={() => setOpenExportDataModelWizard(false)}
                onExportManifest={handleExportManifest}
            />
            <ExportPackageWizard
                open={openExportPackageWizard}
                onClose={handleCloseExportPackageWizard}
            />
        </div>
    </>;
};

export default Admin;