import React, {FC, useMemo} from "react";
import HttpInterceptorContext from "./HttpInterceptorContext";
import axios from "axios";
import config from "../../config";
import useAuth from "../auth/useAuth";
import {useLocation} from "react-router-dom";
import useSessionExpired from "../session_expired/useSessionExpired";
import useSession from "../session/useSession";
import { CDMManager } from "../../core/CDMManager";

const ORG_KEY = 'X-TORO-ECC-ORG';
const NEGRONI_SESSION = 'NEGRONI-SESSION';

/**
 * This provider intercepts the request and response of http.
 *   The request interceptor will inject the user's org in the header.
 *   The response interceptor handles the error messages.
 *     It also handles the token when it gets expired a dialog will pop up to ask the user to log in again.
 *
 * @param children
 */
const HttpInterceptorProvider: FC = ({ children }) => {
    const { openSessionExpiredDialog } = useSessionExpired();
    const { logout, updateAuthState, ssoRefreshToken } = useAuth();
    const { hasRefreshToken, getRefreshToken, user } = useSession();
    const location = useLocation();

    axios.defaults.baseURL = config.base_url;

    useMemo(() => {
        let isRefreshing = false;
        let refreshSubscribers: ((token: string | null) => void)[] = [];

        const subscribeTokenRefresh = (cb: (token: string | null) => void) => {
            refreshSubscribers.push(cb);
        };

        const onTokenRefreshed = (token: string | null) => {
            refreshSubscribers.forEach((cb) => cb(token));
            refreshSubscribers = [];
        };

        const refreshAuthToken = async (): Promise<string | null> => {
            if (!hasRefreshToken()) return null;

            const refreshToken = getRefreshToken();
            try {
                const response = await ssoRefreshToken(refreshToken);
                return response.id_token;
            } catch (error) {
                updateAuthState({ isAuthRunning: false, isAuthenticated: false });
                logout();
                if (
                    !location.pathname.includes('login') &&
                    localStorage.getItem(CDMManager.NEGRONI_LOGIN) !== 'TRUE'
                ) {
                    openSessionExpiredDialog();
                }
                return null;
            }
        };

        // Request Interceptor
        axios.interceptors.request.use((request) => {
            const getCookieValue = (name: string): string | null => {
                const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
                return match ? match[2] : null;
            };

            const negroniSessionCookie = getCookieValue('NEGRONI_SESSION');
          
            request.headers = {
                [ORG_KEY]: user()?.organisationCode || '',
                [NEGRONI_SESSION]: negroniSessionCookie || '',
            };

            return request;
        });

        // Response Interceptor
        axios.interceptors.response.use(
            undefined,
            async (error) => {
                const originalRequest = error.config;
                console.log(error)
                if (!error.response && !axios.isCancel(error)) {
                    window.location.href = '/500.html';
                    return Promise.reject(error);
                }

                if (error.response.status === 401 && !originalRequest._retry) {
                    
                    if (isRefreshing) {
                        return new Promise((resolve, reject) => {
                            subscribeTokenRefresh((newToken) => {
                                if (newToken) {
                                    originalRequest.headers[NEGRONI_SESSION] = newToken;
                                    resolve(axios(originalRequest));
                                } else {
                                    reject(error);
                                }
                            });
                        });
                    }

                    originalRequest._retry = true;
                    isRefreshing = true;

                    const newToken = await refreshAuthToken();

                    if (newToken) {
                        onTokenRefreshed(newToken);
                        isRefreshing = false;
                        originalRequest.headers[NEGRONI_SESSION] = newToken;
                        return axios(originalRequest);
                    }

                    isRefreshing = false;
                    return Promise.reject(error);
                }

                const { status, data } = error.response;

                if (status < 200 || status > 299) {
                    const message =
                        data instanceof ArrayBuffer
                            ? JSON.parse(new TextDecoder('UTF-8').decode(data)).message
                            : data?.message || `Request failed with status ${status}`;
                    return Promise.reject(message);
                }

                return Promise.reject(error);
            }
        );
    }, [logout, user, hasRefreshToken, getRefreshToken, ssoRefreshToken, updateAuthState, openSessionExpiredDialog, location.pathname]);

    return (
        <HttpInterceptorContext.Provider value={undefined as any}>
            {children}
        </HttpInterceptorContext.Provider>
    );
};

export default HttpInterceptorProvider;