import React, { useEffect } from "react";
import {RouteProps} from "react-router";
import {Redirect, Route} from "react-router-dom";
import useAuth from "../auth/useAuth";
import Cookies from "js-cookie";
import { SESSION_KEY, REFRESH_TOKEN } from "../session/SessionProvider";
import useSession from "../session/useSession";

const ProtectedRoute: React.FC<RouteProps> = (props) => {
    const {generateState, storeState, getAuthState, updateAuthState} = useAuth();
    const {isLoggedIn, getSessionToken} = useSession();

    useEffect(() => {
        const params = new URLSearchParams(window.location.search)

        if ( params.get('code') ) {
            if ( !getAuthState()?.isAuthRunning ) {
                Cookies.remove(SESSION_KEY, {domain: process.env.REACT_APP_COOKIE_DOMAIN});
                Cookies.remove(REFRESH_TOKEN, {domain: process.env.REACT_APP_COOKIE_DOMAIN});
            }
            updateAuthState({isAuthRunning: true, isAuthenticated: false});
        }

        if ( !getAuthState()?.isAuthRunning ) {
            const signInUrl = process.env.REACT_APP_SSO_LOGIN_URL;
            if ( signInUrl && !getAuthState()?.isAuthenticated ) {
                const stateToken = generateState();
                storeState(stateToken);
                window.location.href = signInUrl + '&state=' + stateToken
            }
        }
    }, [getAuthState()])

    if (getAuthState()?.isAuthenticated && !getSessionToken()) {
        updateAuthState({isAuthRunning: false, isAuthenticated: false})
    }

    return <Route
        {...props}
        children={undefined}
        render={() => {
            return isLoggedIn() ? props.children : <Redirect to='/sso/login'/>
        }}
    />
}

export default ProtectedRoute;