import React, { FC, ReactElement, useState } from "react";
import { createStyles, Drawer, Fab, Hidden, Theme, useTheme } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { NegroniIcon } from "./icon/NegronIIcon";

const drawerWidth = 280;

// Styles for the component
const navigatorStyles = makeStyles((theme: Theme) =>
    createStyles({
        drawer: {
            [theme.breakpoints.up('sm')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        drawerClose: {
            [theme.breakpoints.up('sm')]: {
                width: 1,
                flexShrink: 0,
            },
        },
        drawerPaper: {
            width: drawerWidth,
            marginTop: 0,
            borderRight: 0,
            [theme.breakpoints.up('sm')]: {
                marginTop: 49,
                height: 'calc(100% - 49px)',
            },
        },
        persistentDrawerPaper: {
            width: drawerWidth,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            marginTop: 0,
            [theme.breakpoints.up('sm')]: {
                marginTop: 49,
                height: 'calc(100% - 49px)',
            },
            overflowX: 'hidden',
        },
        persistentDrawerOpen: {
            width: drawerWidth,
            marginLeft: "50px", // side icon bar size
        },
        persistentDrawerClose: {
            overflowX: 'hidden',
            marginLeft: `-${drawerWidth - 0}px`
        },
        navigatorIcon: {
            minWidth: 35,
        },
        fab: {
            marginLeft: '-10px',
            marginTop: '144px',
        },
        splitterOpen: {
            position: 'fixed',
            width: '5px',
            zIndex: 1201,
            height: '100%',
            display: 'block',
            cursor: 'pointer',
            '&:hover': {
                borderLeft: 'solid var(--secondary-color) 2px',
            },
            marginLeft: `${drawerWidth - 1}px`,
        },
        splitterClose: {
            position: 'fixed',
            width: '5px',
            zIndex: 1201,
            height: '100%',
            display: 'block',
            cursor: 'pointer',
            '&:hover': {
                borderLeft: 'solid var(--secondary-color) 2px',
            },
            marginLeft: '0px',
        },
    }),
);

interface SidebarContainerProps {
    children: (openPersistentDrawer: boolean) => ReactElement;
    onMobileClose: () => void;
    mobile: boolean;
    openPersistentDrawer: boolean;
}

const SidebarContainer: FC<SidebarContainerProps> = ({ children, onMobileClose, mobile, openPersistentDrawer }) => {
    const theme = useTheme();
    const [showSidebarFab, setShowSidebarFab] = useState(false);
    const classes = navigatorStyles();

    return <>
        <div id="negroni-sidebar-container" className={openPersistentDrawer ? classes.drawer : classes.drawerClose} style={{ marginTop: '49px' }}>

            <Hidden smUp>
                <Drawer
                    container={window.document.body}
                    variant="temporary"
                    anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                    open={mobile}
                    onClose={onMobileClose}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    elevation={0}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >
                    {children(openPersistentDrawer)}
                </Drawer>
            </Hidden>
            <Hidden xsDown>
                <Drawer
                    className={clsx(classes.persistentDrawerPaper, {
                        [classes.persistentDrawerOpen]: openPersistentDrawer,
                        [classes.persistentDrawerClose]: !openPersistentDrawer,
                    })}
                    classes={{
                        paper: clsx(classes.persistentDrawerPaper, {
                            [classes.persistentDrawerOpen]: openPersistentDrawer,
                            [classes.persistentDrawerClose]: !openPersistentDrawer,
                        })
                    }}
                    variant="permanent"
                    elevation={0}
                    anchor='left'
                >
                    {children(openPersistentDrawer)}
                </Drawer>
            </Hidden>
        </div>
    </>;
}

export default SidebarContainer;
